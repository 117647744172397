<template>
  <div class="pwa-prompt" v-if="shown">
    Add app to home screen?

    <button @click="installPWA">
      Install!
    </button>

    <button @click="dismissPrompt">
      No, thanks
    </button>
  </div>
  <div class="pwa-hint-panel ios" v-if="showIosHintPanel">
    iOS hint panel
  </div>
  <div class="pwa-hint-panel android" v-if="showAndroidHintPanel">
    android hint panel
  </div>
</template>

<script>
export default {
  data: () => ({
    shown: false,
    showIosHintPanel: false,
    showAndroidHintPanel: false
  }),

  beforeMount() {
    this.showPWAPrompt();
  },

  methods: {

    dismissPrompt() {
      this.shown = false
    },
    showPWAPrompt() {
      console.log(navigator.userAgent)
      if(this.$filters.isMobile().any() != null && this.$filters.isStandalone() !== true) this.shown = true;
    },

    installPWA() {
      if(this.$filters.isMobile().iOS()){
        this.showIosHintPanel = true;
      }else {
        this.showAndroidHintPanel = true;
      }
    },
  }
}
</script>
<style lang="scss">
.pwa-prompt{
  position: fixed;
  z-index: 99998;
  bottom: 0;
  text-align: center;
  left: 0;
  background: #192744FF;
  width: 100%;
  height: 4em;
  line-height: 4em;
  color: white;
}
.pwa-hint-panel{
  position: fixed;
  z-index: 99999;
  bottom: 0;
  text-align: center;
  left: 0;
  background: #192744FF;
  width: 100%;
  height: 4em;
  line-height: 4em;
  color: white;
  ios{

  }
  android{

  }
}
</style>