import {createRouter, createWebHashHistory} from 'vue-router';
import App from './App.vue';
import {getUser} from './main'

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: 'patient-guide',
                name: 'patientGuide',
                component: () => import('./components/PatientGuide.vue')
            }
        ]
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (getUser() == null || getUser().token == null ) {
            next('/login')
            return
        }
    }
    next()
});

export default router;