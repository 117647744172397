import GuidelineService from "@/service/GuidelineService";

export default {
    namespaced: true,
    state: () => ({
        guidelineService: new GuidelineService(),
        guidelines: [],
        selectedGuide: null
    }),
    mutations: {
        guidelines(state,guidelines){
            state.guidelines = guidelines
        },
        selectedGuide(state,selectedGuide){
            state.selectedGuide = selectedGuide
        }
    },
    getters:{
        guidelines(state){
            return state.guidelines
        },
        selectedGuide(state){
            return state.selectedGuide
        },
        tags(state){
            const tags = new Set();
            if(state.guidelines != null){
                state.guidelines.forEach(guideline => {
                    if(guideline.tags != null) guideline.tags.forEach(tag => tags.add(tag))
                })
            }
            return Array.from(tags)
        }
    },
    actions:{
        guidelines(context) {
            context.dispatch("startLoading", null , {root:true});
            context.state.guidelineService.guidelines().then( result => {
                //console.log(result)
                const bookmarks = localStorage.esmo_patient_guides_bookmarks;
                if(context.rootState.baseCookies === true && bookmarks != null){
                    JSON.parse(bookmarks).forEach(asset_id => {
                        result.filter(guideline => guideline.asset_id === asset_id)
                            .forEach(guideline => {guideline.bookmarked = true;})
                    })
                }
                context.commit("guidelines",result);
                context.dispatch("stopLoading", null , {root:true});
            }).catch(e => {
                console.log(e)
                //todo aprire popup service error
                context.dispatch("resetLoading", null , {root:true});
            })
        }
    }
}
