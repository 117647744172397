<template>
	<div class="layout-topbar">
    <button class="layout-topbar-logo p-link">
      <img id="layout-topbar-logo" alt="logo" src="layout/images/logo.png"/>
    </button>
    <span class="topbar-title">{{$t("topbar.title")}}</span>
  </div>
</template>

<script>
export default {
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return 'images/logo.png';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>