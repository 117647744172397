import {createApp, reactive} from 'vue';
import {i18n} from "./plugins/i18n";
import IdleVue from 'idle-vue-3'
import router from './router';
import store from './store';
import axios from 'axios';
import moment from 'moment';
import {saveAs} from 'file-saver';
import AppWrapper from './AppWrapper.vue';
import PrimeVue from 'primevue/config';
import Cloudinary from 'cloudinary-vue';
import VuePdfEmbed from 'vue-pdf-embed';
import { createGtm } from '@gtm-support/vue-gtm';
import * as PrimeVueConfig from './primevue';
import 'primevue/resources/primevue.min.css';
import 'font-awesome/css/font-awesome.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/language_flags.css';
import './assets/demo/flags/country_flags.css';
import './registerServiceWorker'

const userLocale =
    navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
const languageNames = new Intl.DisplayNames([userLocale], {
    type: 'language'
});

const app = createApp(AppWrapper);

app.config.globalProperties.$appState = reactive({theme: 'esmo', darkTheme: false});

app.use(PrimeVue, {ripple: true, inputStyle: 'outlined'});
app.use(router);
app.use(
    createGtm({
        id: 'GTM-M8WR25D',
        queryParams: {},
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        nonce: '2726c7f26c', // Will add `nonce` to the script tag
        enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: [], // Don't trigger events for specified router names (optional)
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    }),
);
app.use(store);
app.use(i18n);
app.use(IdleVue, {eventEmitter: app, idleTime: 6000000, store});
app.use(Cloudinary, {
    configuration: {cloudName: 'esmo'}
})
app.component("vue-pdf-embed", VuePdfEmbed)

app.config.globalProperties.$filters = {
    sortByField(array,field){
        const fields = field.split(".");
      return array.sort((a,b) => {
          let aLabel = a
          let bLabel = b
          if(fields.length > 0){
              fields.forEach(f => {
                  aLabel = aLabel[f]
                  bLabel = bLabel[f]
              })
          }
          if(aLabel < bLabel) return -1
          else if(aLabel > bLabel) return 1
          else return 0
      })
    },
    isStandalone() {
        const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
        if (document.referrer.startsWith("android-app://")) {
            return true; // Trusted web app
        } else if ("standalone" in navigator || isStandalone) {
            return true;
        }
        return false;
    },
    isMobile() {
        return {
            Android: function () {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function () {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Samsung: function () {
                return navigator.userAgent.match(
                    /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i,
                );
            },
            Windows: function () {
                return (
                    navigator.userAgent.match(/IEMobile/i) ||
                    navigator.userAgent.match(/WPDesktop/i)
                );
            },
            any: function () {
                return (
                    this.Android() ||
                    this.BlackBerry() ||
                    this.iOS() ||
                    this.Opera() ||
                    this.Windows()
                );
            },
        }
    },
    publicPath() {
        if (module != null && module.exports != null && module.exports.publicPath != null) return module.exports.publicPath;
        return "/"
    },
    download(url,filename) {
        axios.get(url, {responseType: "blob"}).then(response => {
            saveAs(response.data,filename)
        });
    },
    languageName(code) {
        return languageName(code)
    },
    createId(list) {
        return createId(list)
    },
    formatDate(value, format) {
        return formatDate(value, format)
    },
    sendToast(vue, errorKey) {
        if (errorKey != null) {
            vue.$toast.add({
                severity: vue.$t("common.toasts." + errorKey + ".severity"),
                summary: vue.$t("common.toasts." + errorKey + ".summary"),
                detail: vue.$t("common.toasts." + errorKey + ".detail"),
                life: 6000
            });
        }
    },
    deepClone(object) {
        if (object != null) {
            return JSON.parse(JSON.stringify(object))
        }
    },
    isEmpty(object) {
        return isEmpty(object)
    },
    isEditable(elementId, aclRules, editing) {
        return (editing && (aclRules.find(a => a.elementId === elementId) ? aclRules.find(a => a.elementId === elementId).editable : true))
    },
    isVisible(elementId, aclRules) {
        return (aclRules.find(a => a.elementId === elementId) ? aclRules.find(a => a.elementId === elementId).visible : true)
    },
    isClickable(elementId, aclRules) {
        return ((aclRules.find(a => a.elementId === elementId) ? aclRules.find(a => a.elementId === elementId).clickable : true))
    },
    searchUser(query, user) {
        if (user != null) {
            if (user.username != null && user.username.toUpperCase().startsWith(query.toUpperCase())) return user;
            if (user.description != null && user.description.toUpperCase().includes(query.toUpperCase())) return user;
            if (user.email != null && user.email.toUpperCase().includes(query.toUpperCase())) return user;
        }
    },
    searchApplication(query, application) {
        if (application != null) {
            if (application.code != null && application.code.toUpperCase().startsWith(query.toUpperCase())) return application;
            if (application.description != null && application.description.toUpperCase().includes(query.toUpperCase())) return application;
        }
    },
    searchRole(query, role) {
        if (role != null) {
            if (role.code != null && role.code.toUpperCase().startsWith(query.toUpperCase())) return role;
            if (role.description != null && role.description.toUpperCase().includes(query.toUpperCase())) return role;
        }
    }
}
PrimeVueConfig.init(app);

app.mount('#app');

export function getAxios() {
    axios.defaults.baseURL = store.getters['endpoint'];
    if (getUser() != null && getUser().token != null) {
        axios.defaults.headers.common.authorization = `Bearer ${getUser() != null ? getUser().token : null}`;
    }
    return axios;
}

export function executeQuery(query, variables) {
    return getAxios().post('/graphql', {query, variables}).then(result => {
        if (result.data.errors != null && result.data.errors.length > 0) {
            console.log(result.data.errors);
            let errorKey = null;
            result.data.errors.forEach(e => {
                if (e.exception != null && e.exception.errorKey != null) {
                    errorKey = e.exception.errorKey;
                }
            })
            throw {
                request: {status: 200},
                response: {data: {errorKey: errorKey != null ? errorKey : 'graphql'}}
            };
        }
        if (result.data.data != null) {
            return result.data.data[Object.keys(result.data.data)[0]];
        }
    }).catch(e => {
        if (e.request.status === 403) {
            setUser(null);
        }
        throw e;
    });
}

export function setUser(user) {
    store.commit("setUser", user);
}

export function getUser() {
    if (app != null && store != null && store.state) {
        return store.state.user;
    }
}

export function getEndpoint() {
    if (app != null && store != null && store.state) {
        return store.state.endpoint;
    }
}

export function getFilters() {
    if (app != null) {
        return app.config.globalProperties.$filters
    }
}

export function getVueInstance() {
    return app;
}

export function languageName(code) {
    try {
        return overrideLanguageName(languageNames.of(code).charAt(0).toUpperCase() + languageNames.of(code).slice(1))
    } catch (error) {
        console.error(error);
    }
    return code;
}

export function overrideLanguageName(languageName){
    if(languageName === "Traditional Chinese") return "Chinese (Traditional)"
    if(languageName === "Simplified Chinese") return "Chinese (Simplified)"
    if(languageName === "Indonesian") return "Bahasa Indonesia"
    return languageName
}

export function createId(list) {
    if (list != null) {
        return -1 * (Math.max.apply(Math, list.map(function (o) {
            return o.id;
        })))
    }
    return -1
}

export function formatDate(value, format) {
    if (value && format) {
        return moment(value.toISOString()).format(format)
    }
}

export function isEmpty(object) {
    return (object == null || object === "" || object.length === 0);
}