import {createStore} from 'vuex'
import Guideline from "@/store/modules/guideline";
import GuidelineService from "@/service/GuidelineService";

const store = createStore({
    modules: {
        guideline: Guideline,
    },
    state: {
        baseCookies: localStorage.baseCookies === 'true',
        analyticsCookies: localStorage.analyticsCookies === 'true',
        marketingCookies: localStorage.marketingCookies === 'true',
        resizeTimer:null,
        width: window.innerWidth,
        user: {},
        editing: false,
        loading: 0,
        progressLoaded: 30,
        progressTotal: 0,
        searchValue: null,
        guidelineService: new GuidelineService()
    },
    getters: {
        endpoint: () => {
          if(location.hostname.search("localhost") !== -1){
              return "http://localhost:50001"
          }
          return "https://app-dev.esmo.org" //TODO configure reverse proxy
        },
        loginUrl: () => {
            if(location.hostname.search("production") !== -1){
                return "https://"+store.state.proSalesforce+"/esmo/services/oauth2/authorize?response_type=code&redirect_uri="+store.getters["redirectUrl"]+"&client_id="+store.state.clientId
            } else if(location.hostname.search(store.state.uatDomain) !== -1 || location.hostname.search("localhost") !== -1){
                return "https://"+store.state.uatSalesforce+"/esmo/services/oauth2/authorize?response_type=code&redirect_uri="+store.getters["redirectUrl"]+"&client_id="+store.state.clientId
            }
        },
        redirectUrl: () => {
            if(location.hostname.search("production") !== -1){
                return "https://"+store.state.proDomain+"/%23/oauth2/success"
            }else if (location.hostname.search(store.state.uatDomain) !== -1) {
                return "https://"+store.state.uatDomain+"/%23/oauth2/success"
            } else if (location.hostname.search("localhost") !== -1) {
                return "http://localhost:8080/%23/oauth2/success"
            }
        },
        token: state => {
            if (state.user != null) {
                return state.user.token;
            }
            return null;
        },
        refresh: state => {
            if (state.user != null) {
                return state.user.refresh;
            }
            return null;
        },
        progress: state => {
            let progress = Math.round((state.progressLoaded * 100) / state.progressTotal)
            if (progress > 99) {
                progress = 99
            }
            return progress
        }
    },
    mutations: {
        baseCookies(state, baseCookies) {
            state.baseCookies = baseCookies;
            localStorage.baseCookies = baseCookies;
        },
        analyticsCookies(state, analyticsCookies) {
            state.analyticsCookies = analyticsCookies;
            localStorage.analyticsCookies = analyticsCookies;
        },
        marketingCookies(state, marketingCookies) {
            state.marketingCookies = marketingCookies;
            localStorage.marketingCookies = marketingCookies;
        },
        resizeTimer(state, resizeTimer) {
            state.resizeTimer = resizeTimer;
        },
        width(state, width) {
            state.width = width;
        },
        setUser(state, user) {
            state.user = user;
        },
        setToken(state, token) {
            if (state.user != null) {
                state.user.token = token;
            }
        },
        setRefresh(state, refresh) {
            if (state.user != null) {
                state.user.refresh = refresh;
            }
        },
        setEditing(state, editing) {
            state.editing = editing;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setProgressLoaded(state, progressLoaded) {
            state.progressLoaded = (isNaN(progressLoaded) ? 0 : progressLoaded);
        },
        setProgressTotal(state, progressTotal) {
            state.progressTotal = (isNaN(progressTotal) ? 0 : progressTotal);
        },
        incrementProgressLoaded(state, progressLoaded) {
            state.progressLoaded += (isNaN(progressLoaded) ? 0 : progressLoaded);
        },
        incrementProgressTotal(state, progressTotal) {
            state.progressTotal += (isNaN(progressTotal) ? 0 : progressTotal);
        },
        setInterval(state, interval) {
            state.interval = interval;
        },
        searchValue(state, searchValue) {
            state.searchValue = searchValue;
        }
    },
    actions: {
        clearResizeTimeout(context){
            clearInterval(context.state.resizeTimer)
        },
        startProgress(context) {
            if (context.state.progressLoaded < 70) {
                setTimeout(() => {
                    context.commit("setProgressLoaded", 70)
                }, 1000);
            }
            if (context.state.interval == null) {
                context.state.interval = setInterval(() => {
                    if (context.state.loading === 0) {
                        clearInterval(context.state.interval)
                        context.commit('setInterval', null)
                    } else {
                        context.commit("incrementProgressLoaded", 2)
                    }
                }, 500);
            }
        },
        startLoading(context) {
            context.commit('setLoading', context.state.loading + 1)
            context.dispatch('startProgress')
        },
        stopLoading(context) {
            context.commit("setProgressLoaded", 0);
            context.commit("setProgressTotal", 100);
            if (context.state.loading === 1) {
                clearInterval(context.state.interval)
                context.commit('setInterval', null)
                context.commit("setProgressLoaded", 100)
                setTimeout(() => {
                    context.commit('setLoading', context.state.loading > 0 ? context.state.loading - 1 : 0)
                    context.commit("setProgressLoaded", 0)
                }, 800);
            } else {
                context.commit('setLoading', context.state.loading > 0 ? context.state.loading - 1 : 0)
            }
        },
        resetLoading(context) {
            clearInterval(context.state.interval)
            context.commit('setInterval', null)
            context.commit('setLoading', 0)
            context.commit('setProgressLoaded', 0)
            context.commit('setProgressTotal', 0)
        },
        async logout(context) {
            context.commit('setUser', null)
            context.commit('setEditing', false)
            context.commit('setLoading', 0)
            context.commit('setProgressLoaded', 0)
            context.commit('setProgressTotal', 0)
        }
    }
})

export default store;
