<template>
	<div class="layout-footer">
    <div class="grid" style="max-width: 1024px;margin-left: auto;margin-right: auto;padding: 2em 0 0 0;">
      <div class="col-12 md:col-6 privacy">
        <p class="font-bold ml-2">{{$t("footer.contact-us")}}</p>
        <div class="font-light ml-2" style="text-align: justify-all">To enquire about permission to translate or support the distribution (in English or in
          a translated version) of the Patient Guides series, please send an e-mail to <a href="mailto:patient_guides@esmo.org">patient_guides@esmo.org</a></div>
        <p class="font-bold ml-2" style="margin-top: 1em">{{$t("footer.privacy")}}</p>
        <p class="font-light ml-2"><a href="https://www.esmo.org/terms-of-use" target="_blank">{{$t("footer.terms-of-use")}}</a></p>
        <p class="font-light ml-2"><a href="https://www.esmo.org/terms-of-use/privacy-policy" target="_blank">{{$t("footer.privacy-policy")}}</a></p>
        <p class="font-light ml-2"><span @click="showCookieDialog()" style="text-decoration: underline; cursor: pointer">{{$t("footer.cookies-settings")}}</span></p>

      </div>
      <div class="col-12 md:col-6 useful-links">
        <p class="font-bold ml-2">{{$t("footer.useful-links")}}</p>
        <p class="font-light ml-2"><a href="https://www.esmo.org/for-patients/disclaimer-and-declaration-of-interest" target="_blank">{{$t("footer.declaration-of-interest")}}</a></p>
        <p class="font-light ml-2"><a href="https://www.esmo.org/guidelines" target="_blank">{{$t("footer.clinical-practice-guidelines")}}</a></p>
        <p class="font-light ml-2"><a href="https://www.esmo.org/for-patients/patient-advocates-working-group" target="_blank">{{$t("footer.patient-advocates")}}</a></p>
        <p class="font-light ml-2"><a href="https://www.esmo.org/for-patients/cancer-care-during-the-covid-19-pandemic" target="_blank">{{$t("footer.cancer-care-covid")}}</a></p>
        <p class="font-light ml-2"><a href="https://www.esmo.org/for-patients/getting-the-most-out-of-your-oncologist" target="_blank">{{$t("footer.guide-for-patients")}}</a></p>
        <p class="font-light ml-2"><a href="https://www.esmo.org/about-esmo" target="_blank">{{$t("footer.about")}}</a></p>
      </div>
      <div class="col-12 sub-footer">
        <p>ESMO is a Swiss-registered not-for-profit organisation. All funding for this site is provided directly by ESMO.</p>
        <p>Via Ginevra 4, 6900 Lugano - CH</p>
        <p>© Copyright 2022 European Society for Medical Oncology All rights reserved worldwide.</p>
      </div>
    </div>
    <Dialog v-model:visible="cookieDialogIsVisible" :closable="false" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '100%'}" position="bottom" :modal="true">
      <div class="grid" style="padding:1em">
        <div class="col-12 lg:col-6">
          This site uses cookies. Some of these cookies are essential, while others help us improve your experience by providing insights into how the site is being used.<br/>
          For more detailed information on the cookies we use, please check our <a href="https://www.esmo.org/terms-of-use/privacy-policy" target="_blank">{{$t("footer.privacy-policy")}}</a>.
        </div>
        <div class="col-12 lg:col-6" style="text-align: right;">
          <Button @click="rejectAllCookies()" class="cookie-buttons reject-all">{{$t("cookies.reject-all")}}</Button>
          <Button @click="acceptAllCookies()" class="cookie-buttons accept-all">{{$t("cookies.accept-all")}}</Button>
          <div @click="toggleCustomiseCookies()" style="text-decoration: underline; cursor: pointer;margin-top: 1em">{{$t("cookies.customise")}}</div>
        </div>
        <div v-if="customiseCookiesIsVisible" class="col-12 grid" style="margin-top: 1em;margin-bottom: 1em;">
          <div class="col-12 md:col-4">
            <InputSwitch v-model="selectedBaseCookies" />
            <p><b>{{$t("cookies.baseCookiesTitle")}}</b></p>
            <p>{{$t("cookies.baseCookiesBody")}}</p>
          </div>
          <div class="col-12 md:col-4">
            <InputSwitch v-model="selectedAnalyticsCookies" />
            <p><b>{{$t("cookies.analyticsCookiesTitle")}}</b></p>
            <p>{{$t("cookies.analyticsCookiesBody")}}</p>
          </div>
          <div class="col-12 md:col-4">
            <InputSwitch v-model="selectedMarketingCookies" />
            <p><b>{{$t("cookies.marketingCookiesTitle")}}</b></p>
            <p>{{$t("cookies.marketingCookiesBody")}}</p>
          </div>
          <div class="col-12">
            <Button @click="saveCookieSettings()" class="cookie-buttons save" style="float: right">{{$t("cookies.save")}}</Button>
          </div>
        </div>
      </div>
    </Dialog>
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
    data() {
      return {
        selectedBaseCookies: true,
        selectedAnalyticsCookies: false,
        selectedMarketingCookies: false,
        cookieDialogIsVisible: false,
        customiseCookiesIsVisible: false
      }
    },
		methods: {
      toggleCustomiseCookies(){
        this.customiseCookiesIsVisible = !this.customiseCookiesIsVisible
      },
      saveCookieSettings(){
        this.$store.commit("baseCookies",this.selectedBaseCookies)
        if(this.selectedBaseCookies !== true){
          delete localStorage.esmo_patient_guides_bookmarks
        }
        this.$store.commit("analyticsCookies",this.selectedAnalyticsCookies)
        this.$store.commit("marketingCookies",this.selectedMarketingCookies)
        this.customiseCookiesIsVisible =false;
        this.hideCookieDialog()
      },
      acceptAllCookies() {
        this.selectedBaseCookies = true
        this.selectedAnalyticsCookies = true
        this.selectedMarketingCookies = true
        this.saveCookieSettings()
      },
      rejectAllCookies() {
        this.selectedBaseCookies = false
        this.selectedAnalyticsCookies = false
        this.selectedMarketingCookies = false
        this.saveCookieSettings()
      },
      showCookieDialog() {
        this.cookieDialogIsVisible = true;
			},
      hideCookieDialog() {
        this.cookieDialogIsVisible = false;
      },
      activateAnalytics(){
        if(this.analyticsCookies === true){
          this.$gtm.enable(true)
        } else {
          this.$gtm.enable(false)
        }
      }
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			},
      baseCookies() {
        return this.$store.state.baseCookies;
      },
      analyticsCookies() {
        return this.$store.state.analyticsCookies;
      },
      marketingCookies() {
        return this.$store.state.marketingCookies;
      }
		},
    mounted() {
      this.selectedAnalyticsCookies = this.analyticsCookies
      this.selectedMarketingCookies = this.marketingCookies
      if(!this.baseCookies === true){
        this.showCookieDialog()
      }
      if(this.analyticsCookies === true){
        this.activateAnalytics()
      }
    },
    watch: {
      analyticsCookies() {
        this.activateAnalytics()
      }
    }
  }
</script>

<style scoped lang="scss">
@import "public/theme/theme-esmo";

.cookie-buttons{
  min-width: 10em;
  font-family: "HelveticaLTW05-BlackCond", Arial, Helvetica, sans-serif;
  padding: .8em;
  border: 2px solid $primaryColor;
  margin: .2em;

  &.reject-all,&.save{
    color: $primaryColor;
    background: transparent;
  }
}

.layout-footer{
  overflow: hidden;
  p {
    margin: 0;
  }
}
.privacy,.useful-links,.contact-us{

  a {
    color: white!important;
    text-decoration: underline;
  }
}
.sub-footer{
  margin-top: 2em!important;
  padding: 2em!important;
  border-top: 1px solid white;
}
.font-bold{
  font-family: "HelveticaLTW05-BlackCond", Arial, Helvetica, sans-serif !important;
  font-size: 1.1em;
  margin-bottom: .6em!important;
}
</style>