import { createI18n } from 'vue-i18n';
import en from '@/lang/en.json';

const messages = { en: en };

getLanguage("https://dam.esmo.org/raw/upload/v"+new Date().getTime()+"/en.json","en")


function getLanguage(url,code){
    fetch(url)
        .then((response) => response.json())
        .then((data) => {
            messages[code] = data
        });
}

export const i18n = createI18n({
    locale: navigator.language,
    fallbackLocale: 'en',
    silentTranslationWarn:true,
    silentFallbackWarn: true,
    messages: messages
})
